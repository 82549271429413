import React, { Component } from "react";

class Privacy extends Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    const s = document.createElement("script"),
      tag = document.getElementsByTagName("script")[0];
    s.src = "https://cdn.iubenda.com/iubenda.js";
    s.id = "iubenda-init-script";
    tag.parentNode.insertBefore(s, tag);
  }

  componentWillUnmount() {
    const existingScript = document.getElementById("iubenda-init-script");
    if (existingScript) {
      const tag = document.getElementsByTagName("script")[0];
      tag.parentNode.removeChild(existingScript);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={"container"}>
          <a
            href="https://www.iubenda.com/privacy-policy/35869220"
            className="datenschutz iubenda-white no-brand iubenda-embed iub-body-embed"
            title="Datenschutz"
          >
            Für Datenschutz bitte hier klicken
          </a>
        </div>

        {/*language=SCSS*/}
        <style jsx>{`
          .container {
            padding: 150px 0;
            max-width: 900px;
            margin: 0 auto;
          }

          .datenschutz {
            text-align: center;
            width: 300px;
            margin: 0 auto;
            display: block;
            margin-top: 100px;
          }

          a {
            text-decoration: none;
            color: #59636d;
            -webkit-box-shadow: 0 2px 0 #ebebec;
            box-shadow: 0 2px 0 #ebebec;
            -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.08);
            border-top-color: #d0d0d0;
            border-left-color: #c3c3c3;
            border-right-color: #c3c3c3;
            border-bottom-color: #b8b9ba;
            display: inline-block;
            padding: 0 8px;
            border: 1px;
            border-style: solid;
            border-radius: 3px;
            font-size: 11px;
            line-height: 18px;
            font-weight: bold;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

export default Privacy;
